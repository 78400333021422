import React from "react"
import ContentSection from "../molecules/ContentSection"
import TableOfContents from "../molecules/TableOfContents"

const InnerPageBodyWithToc = ({ page }) => {
  const tocItems = page.pageContent
    .map(section => {
      if (section.title) {
        return section.title
      }
      return null // Skip this item
    })
    .filter(Boolean)

  return (
    <div className="synkli-section--horizontal-space flex flex-col-reverse lg:flex-row gap-10">
      <div className="w-full lg:w-[70%]">
        {page.pageContent.map((section, index) => (
          <ContentSection
            key={index}
            title={section.title}
            titleEC={section.titleEC}
            description={section.description}
            list={section.list}
            image={section.image}
            imageEC={section.imageEC}
          />
        ))}
      </div>
      <div className="w-full lg:w-[30%]">
        <TableOfContents items={tocItems} />
      </div>
    </div>
  )
}

export default InnerPageBodyWithToc
