import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const Lightbox = ({ image, onClose }) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 px-5"
      onClick={onClose}
    >
      <div className="relative">
        <div className="max-w-[1200px]" onClick={e => e.stopPropagation()}>
          <ImageRenderer
            img={image.path}
            alt={image.alt}
            style={{ cursor: "pointer" }}
          />
        </div>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-white text-black rounded-full text-[24px] flex items-center justify-center w-[35px] h-[35px]"
        >
          &times;
        </button>
      </div>
    </div>
  )
}

export default Lightbox
