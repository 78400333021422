import React from 'react'
import ImageRenderer from './ImageRenderer'

const IconListItem = ({text}) => {
    return (
        <li className='flex gap-3 text-[16px] md:text-[18px] leading-[1.4em] '>
            <div className='min-w-[20px] md:min-w-[28px] max-w-[20px] md:max-w-[28px] relative top-[5px] md:top-[0px]'>
                <ImageRenderer img={'tick-icon-for-li'} alt={'tick-icon-for-li'} />
            </div>
            <span dangerouslySetInnerHTML={{__html : text}} />
        </li>
    )
}

export default IconListItem