// All Blogs
export const PrivacyPolicyContent = {
  title: `Privacy <span class="text-[#B695F8]">Policy</span>`,
  description:
    "At Synkli, ensuring the security of your data and the reliability of our infrastructure is our top priority. We are dedicated to offering a safe and secure environment that remains accessible to all our customers consistently.",
  extraDescription: "Last Updated: February 2024",
  image: {
    path: "circled-pattern",
    alt: "circled-pattern",
  },
  pageContent: [
    {
      title: "Introduction",
      titleEC: "!mt-0",
      description: `Welcome to Synkli. We are here to guide you through our privacy policy and promise to make it as simple as possible. Our policy is for you to understand our practices well and have a good time with us. At MMMK Technologies Pvt Ltd, trading as SYNKLI, we are committed to protecting your privacy and ensuring the security of your personal information. This policy outlines how we collect, use, disclose, and protect your information when you use our website and services. Your privacy matters to us. How we manage your personal information is regulated by the South Australian Privacy Act 1988 (Cth) (Privacy Act) and the Australian Privacy Principles outlined within it. Synkli follows the Government of South Australia’s Information Privacy Principles (“IPPs”) regarding any personal information you disclose via this Website. The IPPs impose responsibilities on departments and agencies of the South Australian Government concerning the gathering, storing, sharing, and other uses of personal data.
        <br><br>
      We understand the importance of maintaining the confidentiality and security of your personal information. Our company strives to provide you with the highest data protection and transparency. Please take a moment to review our policy to understand how we handle your personal information and the measures we take to safeguard it. By accessing or using our website and services, you acknowledge that you have read, understood, and agree to be bound by the terms of this policy.
      <br><br>
      If you have any questions or concerns about our policy or our data practices, please don’t hesitate to contact us at <a href="mailto:support@synkli.com.au">support@synkli.com.au</a>.`,
    },
    {
      title: "Definition of Important Terms",
      description: `During this privacy policy, we will use some terms you might not be familiar with. Here are definitions of some of them for easy understanding:`,
      list: [
        `<span style="font-weight: 600;">Personal Information:</span> Personal information refers to any data or information that identifies or can be used to identify an individual. This may include but is not limited to names, addresses, email addresses, phone numbers, social security numbers, financial information, and any other personally identifiable information. At Synkli, we take the privacy and security of personal information seriously and adhere to strict data protection standards to safeguard the confidentiality and integrity of such data.`,
        `<span style="font-weight: 600;">Data Breach:</span> A data breach occurs when there is unauthorised access to, disclosure of, or loss of sensitive or confidential information. This could include personal information, financial data, intellectual property, or other sensitive information. In the event of a data breach, Synkli will immediately investigate the incident, mitigate any potential harm, and notify affected individuals or authorities as required by law.`,
        `<span style="font-weight: 600;">Anonymous Data:</span> Anonymous data refers to information that has been anonymised or de-identified to remove any personally identifiable elements. This data cannot be used to identify individuals and is often aggregated for statistical analysis, research, or other purposes. Synkli may collect and analyse anonymous data to improve our services, enhance user experience, and develop new features or functionalities while protecting user privacy and confidentiality.`,
        `<span style="font-weight: 600;">Consent:</span> Consent refers to an individual’s voluntary and informed agreement to collect, process, and use their personal information for specific purposes. At Synkli, we obtain explicit consent from users before collecting personal information and provide clear and transparent information about how their data will be used, shared, and protected. Users can withdraw their consent at any time, subject to legal or contractual obligations.`,
        `<span style="font-weight: 600;">Data Protection:</span> Data protection encompasses measures and practices to safeguard the privacy, confidentiality, and integrity of personal information and sensitive data. This includes implementing robust security measures, privacy policies, and data handling procedures to prevent unauthorised data access, disclosure, or misuse. Synkli is committed to upholding the highest data protection standards and adheres to applicable data protection laws and regulations to ensure the privacy and security of user data.`,
        `<span style="font-weight: 600;">Cookies:</span> Cookies are small text files stored on a user’s device that track and record their website browsing activities. Cookies may collect user preferences, site usage patterns, and other data to enhance the user experience, personalise content, and provide targeted advertising. Synkli may use cookies and similar technologies to improve our services, analyse site traffic, and customise user experiences while respecting user privacy preferences and choices.`,
      ],
    },
    {
      title: "You and Us",
      description: `Throughout this document, when we mention “we,” “us,” or “our,” we are referring to Synkli. We understand the importance of transparency and trust when handling your personal information. At Synkli, we refer to our staff as dedicated professionals committed to delivering excellence in service. From developers to customer support representatives, each team member plays a crucial role in providing you with a dependable and user-friendly environment.
        <br><br>
      As for you, our esteemed customers, we view you as partners in our journey toward efficient financial management. Your experience is at the heart of everything we do. Whether you are a business owner seeking a reliable financial solution or an individual managing personal finances, Synkli is here to empower you.
      <br><br>
      This policy outlines how we collect, use, disclose, and protect your information when interacting with our website and services. We believe in clear communication and accountability. If you have any questions or concerns about our policy or data practices, please contact us at <a href="mailto:support@synkli.com.au">support@synkli.com.au</a>. Your feedback is invaluable as we strive to improve our services and maintain your trust continuously.`,
    },
    {
      title: "Who is This Privacy Policy For?",
      description: `This Privacy Policy is intended for all users interacting with Synkli’s website, products, and services. Whether you are a business owner utilising our financial management tools or an individual exploring our platform for personal finance management, this policy governs handling your personal information. For our valued customers, this policy guides us in understanding how we collect, use, disclose, and protect your data. We aim to ensure transparency and clarity regarding our data practices, empowering you to make informed decisions about your privacy and data security when engaging with Synkli.
        <br><br>
      Additionally, this policy is designed to inform our employees and stakeholders about their rights and responsibilities concerning data privacy and security. We believe in fostering a culture of privacy and accountability within our organisation, where every individual understands their role in safeguarding sensitive information and upholding the trust placed in us by our users and customers. At Synkli, we are committed to maintaining the highest privacy and data protection standards. This policy outlines our dedication to protecting your personal information and reflects our ongoing commitment to earning and preserving your trust in our services.`,
    },
    {
      title: "Types of Data We Collect",
      description: `When users visit our website, we gather basic non-personal information to enhance their browsing experience and optimise our platform. This includes IP addresses, browser types, device information, and referring pages. Analysing this data gives us insights into user demographics and behaviours, helping us tailor our services to meet their needs better. Additionally, we utilise cookies and tracking technologies to monitor users’ interactions with our website. These technologies enable us to track metrics like pages visited, time spent on each page, and specific actions taken. By understanding how users engage with our platform, we can make informed decisions to improve usability and functionality.
      <br><br>
      For those who voluntarily opt-in, we offer various subscription services that require providing personal information. Subscribers are asked to provide details such as names, email addresses, and billing addresses. This information is securely stored and used solely for account management and service delivery. Moreover, subscribers opting for premium services must provide billing details, including credit card numbers, expiration dates, and billing addresses. We prioritise the security of this sensitive information, employing industry-standard encryption protocols during transmission and storage. Our commitment to data security ensures that subscriber billing details are protected against unauthorised access or misuse.
      <br><br>
      Subscription preferences are another aspect of subscribers’ data collection. This includes preferences related to subscription plans, renewal options, and notification settings. By allowing subscribers to customise their experience, we aim to enhance user satisfaction and retention.
      <br><br>
      For visitors who browse our website without registering or subscribing, we respect their privacy by collecting only minimal non-personal data for analytical purposes. This data helps us analyse traffic patterns, identify popular content, and optimise website performance without compromising user anonymity.
      <br><br>
      Our data collection practices prioritise user consent and privacy, transparency, and security. While we gather minimal non-personal information from anonymous visitors for analytical purposes, we only collect personal and billing details from subscribers who voluntarily opt for premium services. We handle all user data with the utmost care and in compliance with applicable data protection laws and regulations.`,
    },
    {
      title: "Why Do We Collect Your Personal Information?",
      description: `We collect your personal information for several important reasons, all aimed at enhancing your experience and ensuring the provision of valuable services:`,
      list: [
        `<span style="font-weight: 600;">Service Customization:</span> Understanding your preferences allows us to customise our services to suit your needs better. By collecting information such as your interests, location, and browsing behaviour, we can tailor recommendations, content, and offerings specifically for you.`,
        `<span style="font-weight: 600;">Account Management:</span> Personal information is crucial for managing your account effectively. Your details help us verify your identity, provide access to subscription services, and facilitate communication regarding account-related matters such as billing, updates, and security alerts.`,
        `<span style="font-weight: 600;">Communication and Support:</span> Your contact information enables us to communicate with you efficiently. You can encounter an issue with generating financial reports using Synkli’s software and require technical assistance and a way to communicate your concerns. By providing accurate contact information during account registration, Synkli’s customer support team can promptly address your concerns, provide troubleshooting assistance, and keep you informed about the resolution of your issue via email, phone, or live chat support.`,
        `<span style="font-weight: 600;">Improving User Experience:</span> Analyzing personal data lets us gain insights into user behaviour and preferences. This information guides us in optimising our website, applications, and services to make them more intuitive, user-friendly, and engaging for you and others.`,
        `<span style="font-weight: 600;">Security and Fraud Prevention:</span> Protecting your personal information is paramount. We use your data to safeguard your account from unauthorised access, detect and prevent fraudulent activities, and ensure compliance with security standards and regulatory requirements.`,
        `<span style="font-weight: 600;">Legal and Regulatory Compliance:</span> Collecting personal information may be necessary to comply with legal and regulatory obligations. This includes requirements related to taxation, data protection, anti-money laundering, and other legal frameworks governing our operations.`,
        `<span style="font-weight: 600;">Research and Development:</span> Your anonymised data may be used for research and development purposes. By analysing trends and patterns across user interactions, we can identify opportunities for innovation, develop new features, and enhance existing services to meet evolving user needs better.`,
      ],
    },
    {
      description: `Collecting your personal information is essential for providing a seamless and personalised experience while ensuring our services’ security, integrity, and compliance. We are committed to protecting your privacy and utilising your data responsibly under our policies and applicable regulations.`,
    },
    {
      title: "How We Use Your Information",
      description: `At Synkli, we handle your data with care and responsibility, utilising it in various ways to enhance your experience and ensure the smooth functioning of our services. Firstly, we employ your data to provide and improve our services. This involves processing your personal information to fulfil your requests, deliver products or services, and maintain the functionality and security of our platforms. Your data is instrumental in ensuring that our services meet your expectations and operate efficiently.
      <br><br>
      Communication is another crucial aspect of how we use our data. Your contact information enables us to keep you informed about important updates, announcements, and service-related matters. Whether through email notifications, newsletters, or promotional offers, we use your email address or phone number to deliver relevant information directly to you. This ensures that you stay informed about the latest developments and offerings from Synkli.
      <br><br>
      Personalisation plays a significant role in enhancing your experience with our services. By analysing your preferences, behaviour, and interactions, we tailor content, recommendations, and advertisements to align with your interests and needs. This personalised approach ensures that you receive content and offers that are relevant and meaningful to you, enhancing your overall satisfaction with our services.
      <br><br>
      Analytics and research are essential components of our data usage strategy. We collect and analyse data to gain insights into user behaviour, trends, and patterns. This valuable information guides us in improving our products and services, optimising website performance, and developing new features that better meet the evolving needs of our users. We strive to continuously enhance the user experience through data-driven insights and stay ahead of emerging trends.
      <br><br>
      Legal and regulatory compliance is paramount in our data usage practices. We process your data to comply with applicable laws and regulations, fulfilling our obligations under the law and responding to legal requests when necessary. Additionally, we use your data to protect our rights, property, and safety, as well as those of our users and the public, ensuring a secure and trustworthy environment for all.
      <br><br>
      We use your data responsibly and transparently to improve your experience, personalise our services, and ensure compliance with legal and regulatory requirements. Our commitment to safeguarding your privacy and providing meaningful choices and controls over your data remains central to our operations.`,
    },
    {
      title: "Sharing Your Data Information",
      description: `At Synkli, we prioritise protecting your data and ensuring your privacy, which remains paramount in all our operations. Your consent is paramount when it comes to sharing your data information. We respect your right to control how your data is shared and processed. We obtain your explicit consent before sharing your data for marketing or advertising purposes. Additionally, you can modify your privacy settings and preferences at any time to manage how your data is shared. We understand the importance of transparency and accountability when sharing your data information. Here’s how we handle sharing your data: Sharing your data information is a responsibility we take seriously. We adhere to strict principles and guidelines to ensure that your data is shared securely, ethically, and in accordance with your preferences and South Australian laws. Here’s how we manage the sharing of your data:`,
      list: [
        `<span style="font-weight: 600;">Trusted Partnerships:</span> We may share your data with trusted third-party partners who assist us in delivering our services. These partners include service providers such as payment processors, analytics platforms, and customer support systems. Suppose you purchase a subscription to our services using a credit card. In that case, we may share your payment information with our trusted payment processor to facilitate the transaction securely. Additionally, you can use third-party integration or add-ons within our platform. In that case, we may need to share specific data with the partner to enable seamless integration and functionality.`,
        `<span style="font-weight: 600;">Legal and Regulatory Compliance:</span> In certain circumstances, we may be required to share your data to comply with legal or regulatory obligations. Suppose we receive a lawful request from a government authority or law enforcement agency as part of a criminal investigation or regulatory inquiry. In that case, we may be obligated to share specific user data to comply with the request. For instance, if the law requires, we may disclose user information to assist in investigating fraud, intellectual property infringement, or other criminal activities.`,
        `<span style="font-weight: 600;">Anonymisation and Aggregation:</span> We collect user feedback and usage data to improve our services and identify trends in user behaviour. Before sharing this data with third-party researchers or analysts, we anonymise and aggregate it to remove any personally identifiable information. This ensures that the shared data cannot be traced back to individual users and protects user privacy while providing valuable insights for research or statistical analysis.`,
        `<span style="font-weight: 600;">User Consent:</span> Your consent is paramount when sharing your data information. We respect your right to control how your data is shared and processed. We obtain your explicit consent before sharing your data for marketing or advertising purposes. Additionally, you can modify your privacy settings and preferences at any time to manage how your data is shared. We understand the importance of transparency and accountability when sharing your data information.`,
      ],
    },
    {
      title: "Security Measures",
      description: `At Synkli, we take the security of your data seriously and implement robust measures to safeguard it against unauthorised access, disclosure, alteration, or destruction. Our security practices are designed to protect your privacy and ensure the integrity and confidentiality of your personal information. Here are some of the security measures we employ:`,
      list: [
        `<span style="font-weight: 600;">Encryption:</span> We use encryption technology to secure data transmission and storage, ensuring that sensitive information remains protected from interception or unauthorised access. This includes encrypting data during transmission over the internet using SSL/TLS protocols and encrypting stored data using robust cryptographic algorithms.`,
        `<span style="font-weight: 600;">Access Controls:</span> We enforce strict access controls to limit access to your data to authorised personnel only. This includes implementing role-based access control mechanisms, multi-factor authentication, and strong password policies to prevent unauthorised access to sensitive information.`,
        `<span style="font-weight: 600;">Regular Audits and Monitoring:</span> We conduct regular security audits and assessments to identify and address potential vulnerabilities in our systems and infrastructure. Additionally, we employ continuous monitoring and intrusion detection systems to detect and respond to security threats in real time, minimising the risk of data breaches or unauthorised access.`,
        `<span style="font-weight: 600;">Data Backup and Recovery:</span> We maintain regular data backups and disaster recovery plans to ensure the availability and integrity of your data in the event of unforeseen incidents such as hardware failures, natural disasters, or cyber-attacks. Our backup procedures include regular data replication, off-site storage, and testing of recovery processes to minimise downtime and data loss.`,
        `<span style="font-weight: 600;">Employee Training and Awareness:</span> We provide comprehensive training and awareness programs to our employees to ensure they understand their roles and responsibilities in safeguarding user data. This includes training on security best practices, data handling procedures, and incident response protocols to mitigate the risk of human error or insider threats.`,
        `<span style="font-weight: 600;">Third-Party Security Assessments:</span> We conduct thorough security assessments of our third-party service providers to ensure they adhere to the same high security and compliance standards as we do. This includes evaluating their security controls, data protection practices, and compliance with industry standards and regulatory requirements.`,
      ],
    },
    {
      title: "Data Retention and Deletion",
      description: `At Synkli, we adhere to strict data retention practices to ensure that we only retain your personal information for as long as necessary to fulfil the purposes outlined in this privacy policy. Once your data is no longer needed for the intended purposes, we take appropriate measures to securely delete or anonymise it to prevent unauthorised access or misuse. Here’s how we manage data retention and deletion:`,
      list: [
        `<span style="font-weight: 600;">Retention Period:</span> We retain your personal information for the duration of your relationship with Synkli and as required to comply with legal and regulatory obligations. This may include retaining account information, transaction records, and communication history for a certain period following the termination of your account or the end of our contractual relationship.`,
        `<span style="font-weight: 600;">Data Minimization:</span> We only collect and retain the minimum amount of personal information necessary to fulfil the purposes outlined in this privacy policy. We do not store or retain excessive or irrelevant data not required for business operations or legal compliance purposes.`,
        `<span style="font-weight: 600;">Data Deletion:</span> Upon your request or the termination of your account, we will securely delete or anonymise your personal information from our systems and databases. This includes removing your account details, transaction history, and any other personal data associated with your account. We may retain certain information for archival or legal purposes, such as complying with tax or accounting requirements, resolving disputes, or enforcing our legal rights.`,
        `<span style="font-weight: 600;">Backup Retention:</span> We maintain backup copies of your data for disaster recovery and business continuity. However, these backups are subject to the same data retention and deletion policies as our primary systems. We ensure that backups are securely stored and retained for as long as necessary to fulfil their intended purpose.`,
      ],
    },
    {
      title: "Your Rights and Choices",
      description: `At Synkli, we respect your rights and give you meaningful choices and control over your personal information. We understand privacy is a fundamental right and are committed to empowering you to exercise your rights effectively. Here are some of the rights and choices you have regarding your data:`,
      list: [
        `<span style="font-weight: 600;">Access and Rectification:</span> You have the right to access and correct your personal information held by Synkli. Suppose you believe any of your information needs to be more accurate, complete, or updated. In that case, you can request to update or correct it through your account settings or by contacting our customer support team.`,
        `<span style="font-weight: 600;">Data Portability:</span> You can request a copy of your personal information in a structured, commonly used, and machine-readable format. This allows you to transfer your data to another service provider or platform.`,
        `<span style="font-weight: 600;">Withdrawal of Consent:</span> You have the right to withdraw your consent to processing your personal information at any time. Suppose you wish to refrain from receiving marketing communications or participating in certain data processing activities. In that case, you can update your preferences or opt-out through your account settings or by contacting us directly.`,
        `<span style="font-weight: 600;">Deletion and Erasure:</span> You have the right to request the deletion or erasure of your personal information from our systems and databases. We will honour your request subject to legal and contractual obligations, such as retaining certain information for tax or accounting purposes or complying with regulatory requirements.`,
        `<span style="font-weight: 600;">Objection to Processing:</span> You can object to certain processing activities, such as direct marketing or profiling, where your personal information is used for automated decision-making. We will respect your objection and cease processing your data for such purposes unless we have compelling, legitimate grounds for the processing that override your interests, rights, and freedoms.`,
        `<span style="font-weight: 600;">Privacy Settings and Controls:</span> We provide privacy settings and controls to manage how your personal information is collected, used, and shared. You can customise your preferences, opt out of certain data processing activities, and configure privacy settings through your account dashboard or settings menu.`,
        `<span style="font-weight: 600;">Complaints and Inquiries:</span> If you have any questions, concerns, or complaints about our privacy practices or the handling of your personal information, please contact us at <a href="mailto:support@synkli.com.au">support@synkli.com.au</a>. We take privacy seriously and will investigate and respond to any inquiries or complaints promptly and transparently.`,
      ],
    },
    {
      title: "Data Protection and Limitation of Liability",
      description: `At Synkli, we prioritise the protection of your personal information and employ robust technologies and practices to secure the data you entrust to us. However, in the ever-evolving landscape of technology, unforeseen events such as data breaches, hacking attempts, server malfunctions, or other incidents may occur despite our best efforts.
      <br><br>
      In the unfortunate event of such incidents, while we undertake all reasonable measures to prevent and mitigate any data breaches, we cannot guarantee absolute immunity from such occurrences. Consequently, we cannot assume responsibility for any data leaks or unauthorised access resulting from such events.
      <br><br>
      It is important to understand that while we strive to uphold the highest standards of data security, the inherent risks of the digital environment mean that absolute protection against all potential threats is not feasible. Therefore, by using our platform and services, you acknowledge and accept these inherent risks.
      <br><br>
      Notwithstanding the above, in the event of a data breach, we pledge to promptly investigate the matter, take necessary steps to mitigate any harm caused, and comply with any legal obligations regarding notification of affected individuals or authorities.
      <br><br>
      By engaging with our website and services, you agree that while we are committed to safeguarding your data to the best of our abilities, you acknowledge the possibility of unforeseen incidents and agree that we shall not be held liable for any such occurrences beyond our reasonable control.
      <br><br>
      If you have any concerns or questions regarding our data protection practices or this limitation of liability, please do not hesitate to contact us at <a href="mailto:support@synkli.com.au">support@synkli.com.au</a>. Your privacy and trust are of utmost importance to us, and we are here to address any inquiries you may have.
      <br><br>
      Thank you for entrusting us with your personal information and for your understanding of the complexities involved in maintaining data security in today’s digital landscape.`,
    },
    {
      title: "Synkli Outlook Add-On Privacy Policy",
      description: `This privacy policy outlines how we handle your personal information when you use our Outlook add-on to enhance your email productivity. At SYNKLI, we are committed to protecting your privacy and ensuring the security of your personal information.
      <br><br>
      The Synkli Outlook add-on is designed to streamline your workflow by enabling you to create tasks and leads directly from your email correspondence within the Outlook application. Additionally, the add-on allows you to save email correspondence against client profiles in the Synkli application, enhancing organisation and efficiency.`,
    },
    {
      title: "Here’s how we handle your data within the Synkli Outlook add-on",
      description: " ",
      list: [
        `<span style="font-weight: 600;">Task and Lead Creation:</span> The add-on enables you to create tasks and leads based on the content of your email body. This functionality will help you manage your workflow more effectively by converting relevant email content into actionable tasks or leads within the Synkli application.`,
        `<span style="font-weight: 600;">Correspondence Storage:</span> Email bodies may save correspondence against client profiles in the Synkli application. This feature enhances organisation and ensures that relevant communications are easily accessible within the Synkli platform.`,
        `<span style="font-weight: 600;">Free Usage for Accountants and Team Members:</span> The Synkli Outlook add-on is provided free of charge for all accountants and their team members. This enables accounting professionals to leverage the add-on’s productivity benefits without any associated costs.`,
        `<span style="font-weight: 600;">Single Sign-On:</span> The add-on utilises the same credentials as your Synkli account, providing a seamless user experience and eliminating the need for separate login credentials.`,
        `<span style="font-weight: 600;">Data Privacy:</span> We are committed to protecting your privacy. Do not send your email data to any third party or misuse it for promotional purposes. Your email data is used solely for the abovementioned purposes within the Synkli application. If you have any questions or concerns about our privacy practices or using the Synkli Outlook add-on, please don’t hesitate to contact us at <a href="mailto:support@synkli.com.au">support@synkli.com.au</a>. Your privacy matters to us, and we are dedicated to ensuring a safe and secure experience for all our users.`,
        `<span style="font-weight: 600;">Changes to This Privacy Policy:</span> At Synkli, we may update or modify this privacy policy occasionally to reflect changes in our business practices, legal requirements, or industry standards. We encourage you to review this policy periodically to stay informed about how we collect, use, disclose, and protect your personal information. If we make any material changes to this policy, we will notify you by email or through our website before the changes take effect. Your continued use of our services after posting any revised policy indicates your acceptance of the changes. If you do not agree to the terms of this policy or any updates or modifications thereof, you may choose to discontinue using our services and close your account.`,
        `<span style="font-weight: 600;">Contact Us:</span> If you have any questions, concerns, or feedback about this privacy policy or our data practices, please contact us at <a href="mailto:support@synkli.com.au">support@synkli.com.au</a>. We are here to assist you and address any inquiries or concerns you may have regarding your privacy and data security. Your privacy matters to us, and we are committed to maintaining the highest standards of transparency, integrity, and accountability in our privacy practices.`,
      ],
    },
    {
      description: `Thank you for taking the time to review our privacy policy. We appreciate your trust and confidence in Synkli, and we are dedicated to protecting your privacy and ensuring a safe and secure experience for all our users.
        <br><br>
      Last Updated: [22-02-24]`,
    },
  ],
}
