import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const InnerPageBannerWithTitleDescription = ({
  contentAreaEC,
  title,
  titleEC,
  description,
  descriptionEC,
  extraDescription,
  extraDescriptionEC,
  image,
  imageWrapperEC,
}) => {
  return (
    <div className="synkli-section--horizontal-space">
      <div className="mt-20 md:mt-24 mb-10 bg-[#0A1E46] rounded-[25px]">
        <div className="flex flex-col md:flex-row gap-5 items-center">
          <div className={`flex-[2] flex flex-col justify-between gap-4 md:gap-10 px-6 md:pl-10 lg:pl-16 py-10 md:py-16 ${contentAreaEC}`}>
            <h2
              className={`text-white text-[24px] md:text-[30px] lg:text-[48px] leading-[1.2em] font-[700] ${titleEC}`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {description && (
              <p
                className={`text-white text-[18px] lg:text-[20px] leading-[1.4em] font-[300] ${descriptionEC}`}
              >
                {description}
              </p>
            )}
            {extraDescription && (
              <h5
                className={`text-white text-[16px] lg:text-[20px] leading-[1.4em] font-[300] ${extraDescriptionEC}`}
              >
                {extraDescription}
              </h5>
            )}
          </div>
          {image && (
            <div className={`flex-[1] ${imageWrapperEC}`}>
              <div className="max-w-[200px] ml-auto">
                <ImageRenderer img={image.path} alt={image.alt} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InnerPageBannerWithTitleDescription
