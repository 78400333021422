import React, { useState } from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import IconListItem from "../atoms/IconListItem"
import Lightbox from "./Lightbox"

const ContentSection = ({
  title,
  titleEC,
  description,
  list,
  image,
  imageEC,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const generateId = title =>
    title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "")

  return (
    <div className="innner-page--content">
      {title && (
        <h2
          id={generateId(title)}
          className={`text-[22px] md:text-[28px] leading-[1.2em] font-[600] text-[#000000] mt-16 ${titleEC}`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {description && (
        <p
          className="text-[16px] md:text-[18px] leading-[1.4em] mt-6 mb-8"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {list && list.length > 0 && (
        <ul className="flex flex-col gap-5 mb-8">
          {list.map((item, index) => (
            <IconListItem key={index} text={item} />
          ))}
        </ul>
      )}
      {image && (
        <div className={`${imageEC}`} onClick={() => setIsOpen(true)}>
          <ImageRenderer
            img={image.path}
            alt={image.alt}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      {isOpen && (
        <Lightbox
          image={image}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  )
}

export default ContentSection
