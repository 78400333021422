import React from 'react';
import useVisibleSections from '../../hooks/useVisibleSections';

const TableOfContents = ({ items }) => {
  const sectionIds = items.map(item => item.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, ''));
  const visibleSection = useVisibleSections(sectionIds);

  const generateId = (title) => title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');

  return (
    <div className='border rounded-[25px] px-6 py-8 sticky top-[40px]'>
      <h3 className='text-[24px] leading-[1.4em] font-[500] mb-6'>On this page</h3>
      <ul>
        {items.map((item, index) => {
          const id = generateId(item);
          return (
            <li key={index} className='flex justify-start items-stretch gap-4'>
              <div className='flex flex-col justify-start items-center'>
                <span className={`inline-block min-w-[20px] min-h-[20px] rounded-[50%] ${visibleSection === id ? 'bg-[#B695F8]' : 'bg-[#898989]'} border-[4px] border-[#e7e7e7]`}></span>
                {index < items.length - 1 && (
                  <div className={`border border-dashed w-[2px] h-[100%] ${visibleSection === id ? 'border-[#0A1E46]' : 'border-[#C4C2C2]'}`}></div>
                )}
              </div>
              <a href={`#${id}`} className={`text-[18px] leading-[1.2em] ${visibleSection === id ? 'text-[#0A1E46]' : 'text-[#898989]'} ${index === items.length - 1 ? 'mb-0' : 'mb-8'}`} dangerouslySetInnerHTML={{__html: item}} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TableOfContents;
